
<template>
  <div>
    <!-- SOURCE -->
    <div id="printMe">
      <h1>Print me!</h1>
    </div>
    <!-- OUTPUT -->
    <button @click="print"></button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      output: null
    }
  },
  methods: {
    async print () {
      // Pass the element id here
      window.print();
      //await this.$htmlToPaper('printMe');
    }
  }
}
</script>

